import React, { Fragment } from "react";
import { Grid, Header, Loader, Message } from "semantic-ui-react";
import InputComponentConstants from "../Util/InputComponentConstants";
import FieldsEng from "../fieldJson/field_eng.json";
import FieldsDeu from "../fieldJson/field_deu.json";
import FieldsKor from "../fieldJson/field_kor.json";
import FieldsJpn from "../fieldJson/field_jpn.json";
import FieldsPor from "../fieldJson/field_por.json";
import FieldsSpa from "../fieldJson/field_spa.json";
import FieldsAra from "../fieldJson/field_ara.json";
import FieldsFra from "../fieldJson/field_fra.json";
import FieldsHin from "../fieldJson/field_hin.json";
import FieldsIta from "../fieldJson/field_ita.json";
import FieldsRus from "../fieldJson/field_rus.json";
import FieldsHbs from "../fieldJson/field_hbs.json";
import MiscTextEng from "../miscJson/miscText_eng";
import MiscTextKor from "../miscJson/miscText_kor";
import MiscTextJpn from "../miscJson/miscText_jpn";
import MiscTextDeu from "../miscJson/miscText_deu";
import MiscTextPor from "../miscJson/miscText_por";
import MiscTextSpa from "../miscJson/miscText_spa";
import MiscTextAra from "../miscJson/miscText_ara";
import MiscTextFra from "../miscJson/miscText_fra";
import MiscTextHin from "../miscJson/miscText_hin";
import MiscTextIta from "../miscJson/miscText_ita";
import MiscTextRus from "../miscJson/miscText_rus";
import MiscTextHbs from "../miscJson/miscText_hbs";
import ApiAction from "../Util/ApiAction";
import { Redirect } from "react-router-dom";
import CookieUtil from "../Util/CookieUtil";
import { connect } from "react-redux";
import { logout } from "../actions/index";

let configJson = FieldsEng;
let miscText = MiscTextEng;
var timer;

class ProfileEdit extends React.Component {
    constructor(props) {
        super(props);
        console.log(CookieUtil.getCookie("lang"));
        let lang = CookieUtil.getCookie("lang") || "eng";
        this.state = {
            needsLogin: false,
            email: CookieUtil.getCookie("email"),
            loading: false,
            langCode: lang /*props.location.userData
                ? props.location.userData.langCode
                : "",*/,
            userData: {},
            showDoneMessage: this.props.location.showDoneMessage
        };

        switch (lang /*props.location.userData.langcode*/) {
            case "eng":
                configJson = FieldsEng;
				miscText = MiscTextEng;
                break;
            case "kor":
                configJson = FieldsKor;
				miscText = MiscTextKor;
                break;
            case "jpn":
                configJson = FieldsJpn;
				miscText = MiscTextJpn;
                break;
            case "deu":
                configJson = FieldsDeu;
				miscText = MiscTextDeu;
                break;
            case "por":
                configJson = FieldsPor;
				miscText = MiscTextPor;
                break;
            case "spa":
                configJson = FieldsSpa;
				miscText = MiscTextSpa;
                break;
            case "ara":
                configJson = FieldsAra;
				miscText = MiscTextAra;
                break;
            case "fra":
                configJson = FieldsFra;
				miscText = MiscTextFra;
                break;
            case "hin":
                configJson = FieldsHin;
				miscText = MiscTextHin;
                break;
            case "ita":
                configJson = FieldsIta;
				miscText = MiscTextIta;
                break;
            case "rus":
                configJson = FieldsRus;
				miscText = MiscTextRus;
                break;
            case "hbs":
                configJson = FieldsHbs;
				miscText = MiscTextHbs;
                break;
            default:
                configJson = FieldsEng;
				miscText = MiscTextEng;
        }
    }

    getUserData = async () => {
        let email = CookieUtil.getCookie("email");
        let sessionId = CookieUtil.getCookie("sessionId");
        let userData = await ApiAction.getUserData(email, sessionId);
        console.log("user data in edit:", userData);
        return userData.data;
    };

    async componentDidMount() {
        this.setState({ userData: await this.getUserData() });
    }

    //    handleLanguageChange = (e, data) => {
    //        this.setState({ languageSetting: data.langkey });
    //		document.cookie = "lang="+data.langkey;
    //    };

    // componentWillReceiveProps() {
    //     // console.log("in default state",this.state)
    //     var defaultState = {};
    //     if (this.state.userData) {
    //         configJson.forEach(e => {
    //             defaultState[e.fieldName] = this.state.userData[
    //                 e.fieldName
    //             ];
    //         });
    //     }
    //     this.setState(defaultState);
    //     document.cookie = "currentPage=edit";
    // }

    handleMultipleInputDropdownSearchChange = async (e, data) => {
        var fieldName = data.name;
        var value = data.searchQuery;

        var newOptions =
            (await ApiAction.getSuggestValuesByInput(
                fieldName,
                value,
                this.state.email
            )) || [];

        var oldOptions = this.state[fieldName + "Options"] || [];

        var setOptions = new Set();
        oldOptions.forEach(e => {
            setOptions.add(e.key.toUpperCase());
        });
        newOptions.forEach(e => {
            setOptions.add(e.key); //.toUpperCase()
        });
        var finalOptions = [];
        setOptions.forEach(e => {
            finalOptions.push({ key: e, value: e, text: e });
        });

        this.setState({
            [fieldName + "Options"]: finalOptions
        });
        //        this.state.userData[fieldName] = this.state[fieldName];
        this.forceUpdate();
    };

    handleSingleDropdownInputChange = async (e, data) => {
        this.setState({
            [data.name]: data.value,
            loading: true
        });
        let res = await ApiAction.updateUser(
            data.name,
            data.value,
            this.state.email
        );
        if (res.data.message === "Invalid security token.") {
            this.props.logout();
            this.setState({ needsLogin: true });
        } else {
            this.setState({ loading: false });
            var fieldName = data.name;
            this.state.userData[fieldName] = this.state[fieldName];
            this.forceUpdate();
        }
    };

    handleSingleDropdownInputSearch = async (e, data) => {
        var fieldName = data.name;
        var value = data.searchQuery;
        var newOptions = await ApiAction.getSuggestValuesByInput(
            fieldName,
            value,
            this.state.email
        );

        this.setState({
            [fieldName + "Options"]: newOptions
        });
        //        this.state.userData[fieldName] = this.state[fieldName];
        this.forceUpdate();
    };

    handleMultipleDropdownChange = async (e, data) => {
        //debugger;
        this.setState({
            [data.name]: data.value.map((input, index) => {
                return input.toUpperCase();
            }),
            loading: true
        });
        let res = await ApiAction.updateUser(
            data.name,
            data.value,
            this.state.email
        );
        if (res.data.message === "Invalid security token.") {
            this.props.logout();
            this.setState({ needsLogin: true });
        } else {
            this.setState({ loading: false });
            var fieldName = data.name;
            this.state.userData[fieldName] = this.state[fieldName];
            this.forceUpdate();
        }
        //debugger;
    };

    handleSingleDropdownChange = async (e, data) => {
        this.setState({ [data.name]: data.value, loading: true });

        let res = await ApiAction.updateUser(
            data.name,
            data.value,
            this.state.email
        );
        if (res.message === "Invalid security token.") {
            this.props.logout();
            this.setState({ needsLogin: true });
        } else {
            this.setState({ loading: false });
            var fieldName = data.name;
            this.state.userData[fieldName] = this.state[fieldName];
            this.forceUpdate();
        }
    };

    handleTextInputChange = input => async e => {
        let prevUserData = this.state.userData;
        let value = e.target.value;
        let email = this.state.email;
        this.setState({
            userData: {
                ...prevUserData,
                [input]: e.target.value
            }
        });

        let res;
        clearTimeout(timer);
        timer = setTimeout(async function() {
            res = await ApiAction.updateUser(input, value, email);

            if (res.data.message === "Invalid security token.") {
                this.props.logout();
                this.setState({ needsLogin: true });
            }
        }, 2000);
    };

    textInputTimer = () => {
        clearTimeout(timer);
        timer = setTimeout(function() {
            console.log("time");
        }, 2000);
    };

    handleAddItem = async (e, data) => {
        var newValue = {
            key: data.value.toUpperCase(),
            text: data.value.toUpperCase(),
            value: data.value.toUpperCase()
        };
        var currentValue = this.state[data.name + "Options"] || [];
        if (currentValue.find(el => el.key === newValue.key));
        else currentValue.push(newValue);
        this.setState({
            [data.name + "Options"]: currentValue
        });
        var fieldName = data.name;
        this.state.userData[fieldName] = this.state[fieldName];
        this.forceUpdate();
        //this.values = this.state.userData[fieldName];
        //this.handleCategoryFocus(e, data);
        //debugger;
        //this.setState({ [data.name]: currentValue.value.map((input, index) => { return (input.key.toUpperCase())}), loading: true });

        //debugger;
    };

    handleFreeDropdownSearch = async (e, data) => {
        var fieldName = data.name;
        //var fieldName = configJson[this.state.currentIndex].fieldName;
        var value = data.searchQuery;

        var newOptions =
            (await ApiAction.getSuggestValuesByInput(
                "",
                value,
                this.state.email
            )) || [];

        var oldOptions = this.state[fieldName + "Options"] || [];

        var setOptions = new Set();
        oldOptions.forEach(e => {
            setOptions.add(e.key.toUpperCase());
        });
        newOptions.forEach(e => {
            setOptions.add(e.key.toUpperCase());
        });
        var finalOptions = [];
        setOptions.forEach(e => {
            finalOptions.push({ key: e, value: e, text: e });
        });

        this.setState({
            [fieldName + "Options"]: finalOptions
        });
        //        this.state.userData[fieldName] = this.state[fieldName];
        this.forceUpdate();
    };

    handleCategoryFocus = async (e, data) => {
        //debugger;
        var options = await ApiAction.getSuggestValuesByCategory(
            data.name,
            this.state.email
        );
        this.setState({ [data.name + "Options"]: options });
        //debugger;
    };

    generateComponentList = configJson => {
        var components = [];

        configJson.forEach(config => {
            if (config.inputType === "text") {
                components.push(
                    InputComponentConstants.textInput(
                        config,
                        this.handleTextInputChange,
                        this.state.userData[config.fieldName]
                    )
                );
            } else if (config.inputType === "dropdown single category") {
                let options = this.state[config.fieldName + "Options"] || [];

                if (options.length === 0) {
                    options = [
                        {
                            key: this.state.userData[config.fieldName],
                            value: this.state.userData[config.fieldName],
                            text: this.state.userData[config.fieldName]
                        }
                    ];
                }

                let value = this.state.userData[config.fieldName];
                if (value === "") value = undefined;

                components.push(
                    InputComponentConstants.dropdownSingleCategory(
                        config,
                        this.handleSingleDropdownChange,
                        this.handleCategoryFocus,
                        value,
                        options,
                        this.handleAddItem
                    )
                );
            } else if (config.inputType === "dropdown multiple category") {
                let options = this.state[config.fieldName + "Options"] || [];
                let values = this.state.userData[config.fieldName] || [];

                values.forEach(e => {
                    if (options.find(el => el.key === e));
                    else
                        options.push({
                            value: e.toUpperCase(),
                            key: e.toUpperCase(),
                            text: e.toUpperCase()
                        });
                });

                if (values.length === 1 && values[0] === "") values = [];

                components.push(
                    InputComponentConstants.dropdownMultipleCategory(
                        config,
                        this.handleMultipleDropdownChange,
                        this.handleAddItem,
                        this.handleCategoryFocus,
                        values,
                        options
                    )
                );
            } else if (config.inputType === "dropdown single input") {
                let options = this.state[config.fieldName + "Options"] || [];

                if (options.length === 0) {
                    options = [
                        {
                            key: this.state.userData[config.fieldName],
                            value: this.state.userData[config.fieldName],
                            text: this.state.userData[config.fieldName]
                        }
                    ];
                }

                components.push(
                    InputComponentConstants.dropdownSingleInput(
                        config,
                        this.handleSingleDropdownInputChange,
                        this.handleSingleDropdownInputSearch,
                        this.state.userData[config.fieldName],
                        options,
                        this.handleAddItem
                    )
                );
            } else if (config.inputType === "dropdown multiple input") {
                let options = this.state[config.fieldName + "Options"] || [];
                let values = this.state.userData[config.fieldName] || [];

                if (options.length === 0) {
                    values.forEach(e => {
                        if (options.find(el => el.key === e));
                        else
                            options.push({
                                value: e.toUpperCase(),
                                key: e.toUpperCase(),
                                text: e.toUpperCase()
                            });
                    });
                }

                if (values.length === 1 && values[0] === "") values = [];

                components.push(
                    InputComponentConstants.dropdownMultipleInput(
                        config,
                        this.handleMultipleDropdownChange,
                        this.handleAddItem,
                        this.handleMultipleInputDropdownSearchChange,
                        values,
                        options
                    )
                );
            } else if (config.inputType === "dropdown free") {
                let options = this.state[config.fieldName + "Options"] || [];
                let values = this.state.userData[config.fieldName] || [];

                values.forEach(e => {
                    if (options.find(el => el.key === e));
                    else
                        options.push({
                            value: e.toUpperCase(),
                            key: e.toUpperCase(),
                            text: e.toUpperCase()
                        });
                });

                if (values.length === 1 && values[0] === "") values = [];

                components.push(
                    InputComponentConstants.dropdownFree(
                        config,
                        this.handleMultipleDropdownChange,
                        this.handleAddItem,
                        this.handleMultipleInputDropdownSearchChange,
                        values,
                        options
                    )
                );
            } else if (config.inputType === "number") {
                components.push(
                    InputComponentConstants.number(
                        config,
                        this.handleTextInputChange,
                        this.state.userData[config.fieldName]
                    )
                );
            }
        });

        return components;
    };

    handleDismiss = () => {
        this.setState({ showDoneMessage: false });
    };

    isDependsOnSatisfied = (fieldName, requiredValue) => {
        if (fieldName === undefined || requiredValue === undefined) {
            return true;
        } else if (this.state.userData[fieldName] === requiredValue) {
            return true;
        } else if (
            typeof Array.isArray(this.state.userData[fieldName]) &&
            this.state.userData[fieldName] !== undefined &&
            this.state.userData[fieldName].includes(requiredValue.toUpperCase())
        ) {
            console.log("require", requiredValue, "for", fieldName);
            console.log(this.state.userData[fieldName]);
            return true;
        }

        return false;
    };

    render() {
        var inputList = this.generateComponentList(configJson);
        if (this.state.needsLogin) {
            return (
                <Redirect
                    push
                    to={{ pathname: "/login", userData: this.state.userData }}
                />
            );
        }

        if (this.state.userData) {
            return (
                <Fragment>
                    <Grid
                        textAlign="center"
                        style={{
                            marginTop: "10vh",
                            marginBottom: "10vh",
                            width: "100vw"
                        }}
                        verticalAlign="middle"
                    >
                        <Grid.Column style={{ minWidth: 300, maxWidth: 800 }}>
                            <Loader
                                style={{ position: "fixed", left: "50%" }}
                                active={this.state.loading}
                            />
                            {this.state.showDoneMessage && (
                                <Message
                                    size="large"
                                    positive
                                    onDismiss={this.handleDismiss}
                                >
                                    <Message.Header>
                                        {miscText["almost"]}
                                    </Message.Header>
                                    <p>
                                        {miscText["finish"]}
                                    </p>
                                </Message>
                            )}
                            {inputList.map((input, index) => {
                                let dependsOnObject =
                                    configJson[index].dependsOn;
                                let isDependencySatisfied = false;
                                if (dependsOnObject) {
                                    dependsOnObject.forEach(dependency => {
                                        if (
                                            this.isDependsOnSatisfied(
                                                dependency.fieldName,
                                                dependency.value
                                            )
                                        ) {
                                            isDependencySatisfied = true;
                                        }
                                    });
                                }

                                if (isDependencySatisfied || !dependsOnObject) {
                                    return (
                                        <div key={index}>
                                            <Header
                                                style={{
                                                    marginBottom: 0,
                                                    marginTop: 10
                                                }}
                                                content={
                                                    configJson[index]
                                                        .questionText
                                                }
                                            />
                                            {input}
                                        </div>
                                    );
                                }
                            })}
                        </Grid.Column>
                    </Grid>
                </Fragment>
            );
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => {
            // console.log("logout dispatch");
            document.cookie = "loggedIn=false";
            dispatch(logout());
        }
    };
}

export default connect(
    null,
    mapDispatchToProps
)(ProfileEdit);
